import { de, enGB } from 'date-fns/locale';
import { format } from 'date-fns';

export const mixinDate = {
  methods: {
    formatMonth(month: number): string {
      return format(new Date(`${new Date().getFullYear()}-${month}-01`),
        'MMMM',
        {
          locale: (localStorage.getItem('locale') === 'de') ? de : enGB,
        });
    },
  },
};
