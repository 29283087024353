































































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseGridTable from '@/components/base/grid/BaseGridTable.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import LayoutPage from '@/layouts/LayoutPage.vue';
import Vue, { VueConstructor } from 'vue';
import { cloneDeep as _cloneDeep } from 'lodash';
import { MCategory } from '@/models/MCategory';
import { MCustomer } from '@/models/MCustomer';
import { MEarning } from '@/models/MEarning';
import { MInvoice } from '@/models/MInvoice';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinDate } from '@/components/mixins/date';
import { mixinModal } from '@/components/mixins/modal';
import { mixinPDF } from '@/components/mixins/pdf';
import { MProduct } from '@/models/MProduct';
import { MSales } from '@/models/MSales';
import { MTax } from '@/models/MTax';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'IncomeShow',

  components: {
    BaseButtonText,
    BaseGridTable,
    BaseSelect,
    LayoutPage,
  },

  mixins: [
    mixinAlert,
    mixinDate,
    mixinModal,
    mixinPDF,
  ],

  data() {
    return {
      currentYear: String(new Date().getFullYear()),

      gridActionList: [],

      gridColumnDefinition: [
        {
          headerName: String(this.$tc('invoice.property.qty')),
          field: 'quantity',
        },
        {
          headerName: String(this.$tc('product.model', 1)),
          field: 'productName',
        },
        {
          headerName: String(this.$tc('invoice.property.discount', 1)),
          field: 'discount',
        },
        {
          headerName: String(this.$tc('invoice.property.discountPrice', 1)),
          field: 'discountDifference',
        },
        {
          headerName: String(this.$tc('invoice.property.single')),
          field: 'singlePrice',
        },
        {
          headerName: String(this.$tc('earning.property.tax')),
          field: 'tax',
        },
        {
          headerName: String(this.$tc('invoice.property.total')),
          field: 'totalPrice',
        },
      ],

      currentMonth: 'all',

      monthList: [
        {
          month: 'all',
        },
        {
          month: '01',
        },
        {
          month: '02',
        },
        {
          month: '03',
        },
        {
          month: '04',
        },
        {
          month: '05',
        },
        {
          month: '06',
        },
        {
          month: '07',
        },
        {
          month: '08',
        },
        {
          month: '09',
        },
        {
          month: '10',
        },
        {
          month: '11',
        },
        {
          month: '12',
        },
      ],

      yearList: [
        {
          year: 2020,
        },
        {
          year: 2021,
        },
        {
          year: 2022,
        },
        {
          year: 2023,
        },
        {
          year: 2024,
        },
        {
          year: 2025,
        },
        {
          year: 2026,
        },
        {
          year: 2027,
        },
        {
          year: 2028,
        },
        {
          year: 2029,
        },
        {
          year: 2030,
        },
      ],
    };
  },

  computed: {
    categoryIncomeList(): Array<any> {
      const categoryIncomeList = [];

      const taxList = MTax.all();

      this.incomeList.forEach(income => {
        const product = MProduct.find(income.productID);

        const category = MCategory.find(product.CategoryId);

        const categoryExists = categoryIncomeList.find(category => {
          return category.actions === product.CategoryId;
        });

        if (categoryExists !== undefined) {
          if (categoryExists[`${income.tax}`] === undefined) {
            categoryExists[`${income.tax}`] = 0;
          }

          categoryExists.totalPrice += Number(income.totalPrice);

          categoryExists[`${income.tax}`] += Number(income.totalPrice);
        } else {
          const categoryIncome = {};

          taxList.forEach(tax => {
            categoryIncome[`${income.tax}`] = 0;
          });

          categoryIncomeList.push({
            categoryName: category.CategoryName,
            [`${income.tax}`]: Number(income.totalPrice),
            totalPrice: Number(income.totalPrice),
            actions: product.CategoryId,
          });
        }
      });

      return categoryIncomeList;
    },

    displayedYear(): Object {
      return {
        year: this.currentYear,
      };
    },

    displayedMonth(): Object {
      return {
        month: this.currentMonth,
      };
    },

    gridCategoryColumnDefinition(): Array<any> {
      const gridCategoryColumnDefinition = [
        {
          headerName: String(this.$tc('category.model', 1)),
          field: 'categoryName',
        },
        {
          headerName: String(this.$tc('invoice.property.total')),
          field: 'totalPrice',
        },
      ];

      const taxList = MTax.all();

      taxList.forEach(tax => {
        gridCategoryColumnDefinition.push({
          headerName: `${tax.Percent} %`,
          field: `${tax.Percent}`,
        });
      });

      return gridCategoryColumnDefinition;
    },

    invoiceList(): Array<MInvoice> {
      if (this.displayedMonth.month !== 'all') {
        return MInvoice
          .query()
          .withAllRecursive()
          .where(invoice => {
            return invoice.DatePaid !== '' &&
              invoice.DatePaid !== null &&
              invoice?.DatePaid?.substr(0, 4) === this.displayedYear.year &&
              invoice?.DatePaid?.substr(5, 2) === this.displayedMonth.month;
          }).get();
      }

      return MInvoice
        .query()
        .withAllRecursive()
        .where(invoice => {
          return invoice.DatePaid !== '' &&
            invoice.DatePaid !== null &&
            invoice.DatePaid.substr(0, 4) === this.displayedYear.year;
        }).get();
    },

    incomeList(): Array<any> {
      const incomeList = [];

      this.invoiceList.forEach(invoice => {
        let customerIsCompany = false;

        const customer = MCustomer.find(invoice.CustomerID);

        if (customer !== null && customer.Uid !== '') {
          customerIsCompany = true;
        }

        invoice.Sales.forEach(sale => {
          const saleData = sale.asListData;

          let tax = Number(saleData.tax);

          if (customerIsCompany && saleData.tax === '10') {
            tax = 13;
          }

          const incomeExists = incomeList.find(income => {
            return (
              income.discount === saleData.discount &&
              income.flat === saleData.flat &&
              income.productName === saleData.productName &&
              income.tax === tax
            );
          });

          if (incomeExists !== undefined) {
            incomeExists.discountDifference += saleData.discountDifference;

            incomeExists.discountSingleDifference += Number(saleData.discountSingleDifference);

            incomeExists.discountPrice += Number(saleData.discountPrice);

            incomeExists.price += Number(saleData.price);

            incomeExists.totalPrice += Number(saleData.totalPrice);

            const quantity = Number(saleData.quantity.replace(',', '.'));

            incomeExists.quantity += quantity;
          } else {
            const quantity = (saleData.quantity.replace(',', '.'));

            incomeList.push({
              discount: saleData.discount,
              discountDifference: saleData.discountDifference,
              discountSingleDifference: Number(saleData.discountSingleDifference),
              discountPrice: Number(saleData.discountPrice),
              entity: saleData.entity,
              generalized: saleData.generalized,
              flat: saleData.flat,
              price: Number(saleData.price),
              productName: saleData.productName,
              productID: saleData.productID,
              singlePrice: Number(saleData.singlePrice),
              tax: tax,
              totalPrice: Number(saleData.totalPrice),
              quantity: Number(quantity),
              actions: this.Id,
            });
          }
        });
      });

      return incomeList;
    },

    gridRowData(): Array<any> {
      const incomeList = _cloneDeep(this.incomeList);

      return incomeList.map(income => {
        income.totalPrice = MSales.formatedPrice(income.totalPrice);

        income.singlePrice = MSales.formatedPrice(income.singlePrice);

        income.discountDifference = MSales.formatedPrice(income.discountDifference);

        income.quantity = (income.Flat) ? income.entity : `${Number(income.quantity).toFixed(2)} ${income.entity}`;

        return income;
      });
    },

    gridRowDataCategory(): Array<any> {
      const taxList = MTax.all();

      return this.categoryIncomeList.map(categoryIncome => {
        taxList.forEach(tax => {
          if (
            categoryIncome[tax.Percent] !== 0 &&
            categoryIncome[tax.Percent] !== '' &&
            categoryIncome[tax.Percent] !== undefined
          ) {
            categoryIncome[tax.Percent] = MSales.formatedPrice(categoryIncome[tax.Percent]);
          }
        });

        categoryIncome.totalPrice = MSales.formatedPrice(categoryIncome.totalPrice);

        return categoryIncome;
      });
    },

    totalPrice(): string {
      let price = 0;

      this.categoryIncomeList.forEach(categoryIncome => {
        price += categoryIncome.totalPrice;
      });

      return `${this.$t('invoice.property.total')}: ${MSales.formatedPrice(price)}`;
    },
  },

  methods: {
    exportPdf(): void {
      let title = `${this.$tc('income.model', 2)}-${this.currentYear}`;

      if (this.currentMonth !== 'all') {
        title = `${this.$tc('income.model', 2)}-${this.formatMonth(this.currentMonth)}-${this.currentYear}`;
      }

      this.exportIncomeData(this.gridRowData, this.categoryIncomeList, title);
    },

    async loadYear(year: any): Promise<any> {
      this.currentYear = year.year;

      await MEarning._fetch(year.year);
    },

    async loadMonth(month: any): Promise<any> {
      this.currentMonth = month.month;
    },
  },

});
